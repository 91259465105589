import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackToWork from "../components/backtowork"

const InternalPage = () => (
  <Layout>
    <SEO title="Email Templates" />
    <main className="internal">
      <BackToWork />
      <div className="content">
        <div className="full">
          <h1>UI/UX: Email Templates</h1>
          <p>While in the process of transitioning HES emails from an outdated marketing system to MailChimp, the decision to revise the method of how emails were designed was also made. Creating a system of templates would make the process of designing and coding emails faster and more efficient.</p>
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647089/hireallisonokeefe/email-templates/emails_01_skelqp.jpg" alt="" />
            <figcaption>Above: wireframe concepts across all HES email communications, including product marketing, wellness program pilots, and blog posts.</figcaption>
          </figure>
          <p>What became the challenge was taking into consideration that each template would need to be chosen based on whatever content was provided. The content could vary in length and style, depending on what it was being written for.</p>
          <p>Emails for wellness product could include:</p>
          <ul>
            <li>Promoting a specific wellness program</li>
            <li>Sharing of a White Paper or SlideShare</li>
            <li>Announcement for a group demo on a new upcoming wellness program</li>
            <li>Group Demo follow up emails</li>
            <li>Weekly emails during a pilot to participants.</li>
          </ul>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647094/hireallisonokeefe/email-templates/emails_02_mavgxm.png" alt="" />
            <figcaption>Above: HES wellness pilot emails sent to participants.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647093/hireallisonokeefe/email-templates/emails_03_a5a4ut.png" alt="" />
            <figcaption>Above: HES wellness program emails sent to full list of contacts.</figcaption>
          </figure>
        </div>
        <div className="full">
          <p>Other miscellaneous emails that needed to be considered included:</p>
          <ul>
            <li>Direct emails from Account Managers and Implementation Coordinators</li>
            <li>Letters to pilot program participants</li>
            <li>HES Insider (a newsletter from the CEO sent directly to VIP clients, informing them of company goings-ons)
            </li>
            <li>Weekly blog post newsletters, including primary and secondary articles</li>
            <li>Well-Being Practitioner magazine releases.</li>
          </ul>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647094/hireallisonokeefe/email-templates/emails_04_jcrern.png" alt="" />
            <figcaption>Above: Letters from Account Managers to potential clients.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584647094/hireallisonokeefe/email-templates/emails_05_ts4jkn.png" alt="" />
              <figcaption>Above: Blog post newsletters sent to opted-in contact list.</figcaption>
          </figure>
        </div>
        <div className="full">
          <p>Creating these basic templates also meant that designers with basic knowledge of HTML would be able to build any type of email efficiently.</p>
          <h2>Results</h2>
          <p>With the implementation of these templates, we were able to go from building and putting out 2 emails a week to N+3 and being able to prepare emails up to 3 weeks out.</p>
          <p><span className="text--bold">Programs used:</span> Photoshop, MailChimp</p>
        </div>
      </div>
    </main>
  </Layout>
)

export default InternalPage
